import { create } from "zustand";

export interface IToasterStore {
  message: string;
  setMessage: (message: string) => void;
  status: number;
  setStatus: (status: number) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  setEmpty: () => void;
}

export const useToasterStore = create((set) => ({
  message: "",
  setMessage: (message: string) => set({ message }),
  status: 0,
  setStatus: (status: number) => set({ status }),
  loading: false,
  setLoading: (loading: boolean) => set({ loading }),
  setEmpty: () => set({ message: "", status: 0, loading: false }),
}));

export const setStateResponse = ({
  message,
  status,
}: {
  message: string;
  status: number;
}) => {
  (useToasterStore.getState() as IToasterStore).setMessage(message);
  (useToasterStore.getState() as IToasterStore).setStatus(status);
};
