import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import { ILot, LotCreationForm } from "@Models/interfaces/lots.interface";

// import { adapterGetGiftcardById } from "@Adapters/giftcards/getGiftcardById.adapter";
import { debounce } from "lodash";
import {
  useToasterStore,
  IToasterStore,
  setStateResponse,
} from "@ReduxService/useToasterStore";
import { getSearchSupplierByName_adapter } from "@Adapters/Supplier/getSearchSupplierByName.adapter";
import { createLotAdapter } from "@Adapters/lots/createLot.adapter";
// import { validateGiftcardCode } from "@Services/giftcards/validateGiftcardCode.service";
// import { updateLot_adapter } from "@Adapters/lots/updateLot.adapter";

interface Supplier {
  id: number;
  name: string;
}

export const useLotCreationForm = (
  isEdit?: boolean,
  initialData?: LotCreationForm | null,
  readMode?: boolean,
  onSuccess?: (lot: ILot) => void
) => {
  const { id } = useParams();
  const productId = id as unknown as number;
  const { setStatus, setMessage } = useToasterStore() as IToasterStore;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCodeLoading, setIsCodeLoading] = useState(false);

  // Nuevo estado para proveedores
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [isLoadingSuppliers, setIsLoadingSuppliers] = useState(false);
  const [supplierError, setSupplierError] = useState("");

  // Función para formatear la fecha al formato deseado
  const formatDate = (date: Date): string => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  // Obtener la fecha de hoy formateada
  const today = formatDate(new Date());

  // Actualizamos el estado del formulario para incluir el proveedor
  const [lotForm, setLotForm] = useState<LotCreationForm>({
    code: "",
    unitPrice: "",
    provider: undefined,
    providerSearch: "",
    entryDate: today,
    expirationDate: "",
    units: "",
  });

  const [lotFormError, setLotFormError] = useState({
    code: "",
    unitPrice: "",
    entryDate: "",
    expirationDate: "",
    units: "",
  });

  const initialCodeValid = true;
  const [isCodeValid, setIsCodeValid] = useState(initialCodeValid);
  const [codeError, setCodeError] = useState("");

  const loadGiftcardData = async () => {
    // const response = await adapterGetGiftcardById(productId);
    // if (response.isSuccess) {
    //    setGiftcardForm(response.data);
    // }
  };

  // useEffect(() => {
  // 	if ((isEdit || readMode) && productId) {
  // 		loadGiftcardData();
  // 	}
  // }, [isEdit, productId]);

  useEffect(() => {
    if ((isEdit || readMode) && initialData) {
      setLotForm(initialData);
      // Si estamos en modo edición, el código ya está validado
      setIsCodeValid(true);
    } else {
      setLotForm({
        code: "",
        unitPrice: "",
        provider: undefined,
        providerSearch: "",
        entryDate: today,
        expirationDate: "",
        units: "",
      });
      setLotFormError({
        code: "",
        unitPrice: "",
        entryDate: "",
        expirationDate: "",
        units: "",
      });
      setIsCodeValid(initialCodeValid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, initialData]);

  const validateCode = async (code: string) => {
    if (code.length < 5) {
      setCodeError("El código debe tener al menos 5 caracteres");
      setIsCodeValid(initialCodeValid);
      return;
    }

    if (!/(?=.*[A-Z])(?=.*[0-9])/.test(code)) {
      setCodeError("El código debe contener al menos una letra y un número");
      setIsCodeValid(initialCodeValid);
      return;
    }

    setIsCodeLoading(true);
    setCodeError("");

    // const response = await validateGiftcardCode(code);
    // if (!response.isSuccess || !response.isAvailable) {
    // 	setCodeError("El código no está disponible");
    // 	setIsCodeValid(false);
    // } else {
    // 	setIsCodeValid(true);
    // }

    setIsCodeLoading(false);
  };

  const debouncedValidateCode = debounce(validateCode, 300);

  // Función para obtener los proveedores
  const fetchSuppliers = async () => {
    if (!lotForm.providerSearch) return;
    setIsLoadingSuppliers(true);
    setSupplierError("");

    try {
      const response = await getSearchSupplierByName_adapter(
        lotForm.providerSearch,
        100
      );

      if (!response.isSuccess) {
        throw new Error("Error al cargar los proveedores");
      }

      const adaptedData = response.data.map((supplier) => {
        return {
          id: supplier.id,
          label: supplier.name,
          subtitle: supplier.taxNumber,
        };
      });

      setSuppliers(adaptedData);
    } catch (error) {
      setSupplierError("Error al cargar los proveedores");
      console.error("Error fetching suppliers:", error);
    } finally {
      setIsLoadingSuppliers(false);
    }
  };

  useEffect(() => {
    fetchSuppliers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lotForm.providerSearch]);

  // Handlers
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    if (name === "providerSearch") {
      setLotForm((prev) => ({
        ...prev,
        providerSearch: value,
      }));
      return;
    }

    if (name === "provider") {
      // @ts-ignore
      setLotForm((prev) => ({
        ...prev,
        provider: value ? value : undefined,
      }));
      return;
    }

    if (name === "code") {
      const upperValue = value.toUpperCase();
      if (upperValue.length > 10) return;
      if (!/^[A-Z0-9]*$/.test(upperValue)) return;

      setLotForm((prev) => ({
        ...prev,
        [name]: upperValue,
      }));

      // if (upperValue.length < 5) {
      // 	setCodeError("El código debe tener al menos 5 caracteres");
      // 	setIsCodeValid(false);
      // } else if (!/(?=.*[A-Z])(?=.*[0-9])/.test(upperValue)) {
      // 	setCodeError("El código debe contener al menos una letra y un número");
      // 	setIsCodeValid(false);
      // } else {
      // 	debouncedValidateCode(upperValue);
      // }
      return;
    }

    if (name === "units") {
      if (!/^\d*$/.test(value)) return;
      if (Number(value) < 0) return;

      setLotForm((prev) => ({
        ...prev,
        [name]: value,
      }));
      // Limpiar error si existe
      if (lotFormError.units) {
        setLotFormError((prev) => ({
          ...prev,
          units: "",
        }));
      }
      return;
    }

    setLotForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleProviderSearch = (search: string) => {
    setLotForm((prev) => ({
      ...prev,
      providerSearch: search,
    }));
  };

  // Función para verificar si el formulario es válido
  const isFormValid = (): boolean => {
    return !!(
      lotForm.code &&
      // isCodeValid &&
      lotForm.code.length >= 5 &&
      lotForm.units &&
      lotForm.unitPrice &&
      lotForm.provider &&
      // No incluimos providerSearch ya que no es requerido
      lotForm.entryDate &&
      lotForm.expirationDate
    );
  };

  // Actualizamos handleCreateLot para validar todos los campos
  const handleCreateLot = async () => {
    let errorObj = {
      code: "",
      provider: "",
      unitPrice: "",
      entryDate: "",
      expirationDate: "",
      units: "",
    };

    if (!lotForm.units) {
      errorObj.units = "El campo de unidades es obligatorio";
    }

    if (!lotForm.code || lotForm.code.length < 5) {
      errorObj.code = "El código debe tener al menos 5 caracteres";
    }

    if (!isCodeValid) {
      errorObj.code = "El código no es válido";
    }

    if (!lotForm.provider) {
      errorObj.provider = "Debe seleccionar un proveedor";
    }

    if (!lotForm.unitPrice) {
      errorObj.unitPrice = "El precio unitario es obligatorio";
    }

    if (!lotForm.entryDate) {
      errorObj.entryDate = "La fecha de ingreso es obligatoria";
    }

    if (!lotForm.expirationDate) {
      errorObj.expirationDate = "La fecha de vencimiento es obligatoria";
    }

    if (!lotForm.units) {
      errorObj.units = "El campo de unidades es obligatorio";
    }

    setLotFormError(errorObj);
    if (Object.values(errorObj).some((error) => error !== "")) return;

    setIsSubmitting(true);
    try {
      if (isEdit) {
        // Lógica para actualizar el lote
        // const response = await updateLot_adapter(lotForm);
        const response = {
          isSuccess: true,
          data: {
            id: 1,
            code: lotForm.code,
            units: Number(lotForm.units),
            unitPrice: Number(lotForm.unitPrice),
            provider: {
              id: lotForm.provider.id,
              name: lotForm.provider.label,
              companyId: lotForm.provider.subtitle,
            },
            entryDate: lotForm.entryDate,
            expirationDate: lotForm.expirationDate,
            status: "Activo",
          },
        };
        if (response.isSuccess) {
          setStatus(200);
          setMessage("Lote actualizado correctamente");
          onSuccess?.(response.data);
        }
      } else {
        const response = await createLotAdapter(
          {
            ...lotForm,
            productId,
          },
          setStateResponse
        );
        // const response = {
        //   isSuccess: true,
        //   data: {
        //     id: 1,
        //     code: lotForm.code,
        //     units: Number(lotForm.units),
        //     unitPrice: Number(lotForm.unitPrice),
        //     provider: {
        //       id: lotForm.provider.id,
        //       name: lotForm.provider.label,
        //       companyId: lotForm.provider.subtitle,
        //     },
        //     entryDate: lotForm.entryDate,
        //     expirationDate: lotForm.expirationDate,
        //     status: "Activo",
        //   },
        // };

        if (response.isSuccess) {
          setStatus(200);
          setMessage("Lote creado correctamente");
          onSuccess?.(response.data);
        }
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    isModalVisible,
    setIsModalVisible,
    handleInputChange,
    handleProviderSearch,
    lotForm,
    lotFormError,
    isSubmitting,
    isCodeValid,
    codeError,
    isCodeLoading,
    handleCreateLot,
    suppliers,
    isLoadingSuppliers,
    supplierError,
    isFormValid: isFormValid(),
  };
};
