import { getData, supplierBaseUrl } from "@Services/constServices";

export const getSearchSupplierByName_service = async (
  search: string,
  maxResultsToShow: number
) => {
  const url = `${supplierBaseUrl}/SearchSupplierByName?search=${search}&maxResultsToShow=${maxResultsToShow}`;
  const successMessage = "Proveedor encontrado correctamente";
  const errorMessage =
    "Hubo un error al buscar el proveedor. Por favor, intente nuevamente.";
  return getData(url, successMessage, errorMessage);
};
