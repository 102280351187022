import { ILot, LotCreationForm } from "@Models/interfaces/lots.interface";
import { setStateResponse } from "@ReduxService/useToasterStore";
import { createLot_service } from "@Services/lots/createLot.service";

interface GetLotsResponse {
  isSuccess: boolean;
  data: ILot | null;
}

export const createLotAdapter = async (
  payload: LotCreationForm & {
    productId: number;
  },
  setStateResponse: (state: { message: string; status: number }) => void
): Promise<GetLotsResponse> => {
  try {
    const adapterPayload = {
      supplierId: payload.provider.id,
      codeBatch: payload.code,
      expirationDate: payload.expirationDate,
      creationDate: payload.entryDate,
      unitCost: Number(payload.unitPrice),
      productId: payload.productId,
      quantity: Number(payload.units),
    };

    const response = await createLot_service(adapterPayload);

    if (!response.isSuccess) {
      throw new Error("Error al crear el lote");
    }

    const lotData = {
      id: response.data.batchId,
      code: payload.code,
      units: Number(payload.units),
      unitPrice: Number(payload.unitPrice),
      provider: {
        id: payload.provider.id,
        name: payload.provider.label,
        companyId: payload.provider.subtitle,
      },
      entryDate: payload.entryDate,
      expirationDate: payload.expirationDate,
      status: response.data.status,
    };

    return {
      isSuccess: true,
      data: lotData,
    };
  } catch (error) {
    setStateResponse({
      message: "Error al crear el lote",
      status: 400,
    });
    return {
      isSuccess: false,
      data: null,
    };
  }
};
