// @ts-ignore
import { Warehouse } from "@Models/interfaces/warehousesInterfaces/Warehouse.interface";
import { useState } from "react";
import { editName } from "../../functions/functionsWarehouseSelect";
import {
  ModalAcceptCancel,
  ButtonWithoutIcon,
  IconWrapper,
  // @ts-ignore
} from "@viuti/recursos";
import ModalOptionsWarehouse from "./ModalOptionsWarehouse/ModalOptionsWarehouse";
import OpenOptionsWarehouse from "./OpenOptionsWarehouse/OpenOptionsWarehouse";
import style from "./WarehouseSearchResults.module.css";
import { HiLockClosed } from "react-icons/hi2";

const WarehouseSearchResults = ({
  warehousesList,
  searchQuery,
  changeWarehouse,
  setSelectWarehouse,
  setWarehouseName,
  setShowWarehouse,
  editOpenModal,
  setIsHovered,
  setButtonDelete,
  showEdit,
  selectedWarehouseId,
  setSelectedWarehouseId,
  setShowEdit,
  closeOptions,
  setWarehouseToEdit,
  setOpenEditModal,
  resetWarehouses,
  setResetWarehouses,
  saleWarehouse,
  deleteWarehouseSelected,
  openCloseSelect,
  showWarehouse,
  buttonDelete,
  isHovered,
  setStateResponse,
  setWarehousesList,
  setResetProducts,
  resetProducts,
}) => {
  const [showDowngradeModal, setShowDowngradeModal] = useState(false);

  const handleWarehouseClick = (wareh, e) => {
    if (wareh.isDowngrade) {
      e.preventDefault();
      setShowDowngradeModal(true);
      return;
    }

    changeWarehouse(
      wareh,
      setSelectWarehouse,
      setWarehouseName,
      setShowWarehouse
    );
  };

  return (
    <div className={style.containerSearchResults}>
      {/*Search Results*/}
      {warehousesList
        ?.filter((ware: Warehouse) =>
          ware?.warehouseName.toLowerCase().includes(searchQuery.toLowerCase())
        )
        .map((wareh: Warehouse, index: number) => (
          <div
            key={wareh?.warehouseId}
            className={`${style.buttonWarehouse} ${
              wareh.isDowngrade ? style.disabled : ""
            }`}
          >
            <button
              className={style.changeStore}
              onClick={(e) => handleWarehouseClick(wareh, e)}
            >
              <span className={style.warehouseNameContainer}>
                <p className={style.warehouseName}>{wareh?.warehouseName}</p>
                {wareh.sales === 1 && <p className={style.saleIcon}>Venta</p>}
              </span>
              {wareh.isDowngrade && (
                <span className={style.disabledTag}>Deshabilitado</span>
              )}
            </button>

            {/* button open options warehouse*/}
            {wareh.isDowngrade ? (
              <IconWrapper icon={HiLockClosed} size={16} color="#bebebe" />
            ) : (
              <OpenOptionsWarehouse
                wareh={wareh}
                editOpenModal={editOpenModal}
                setIsHovered={setIsHovered}
                setButtonDelete={setButtonDelete}
                showEdit={showEdit}
                selectedWarehouseId={selectedWarehouseId}
                setSelectedWarehouseId={setSelectedWarehouseId}
                setShowEdit={setShowEdit}
                closeOptions={closeOptions}
                disabled={wareh.isDowngrade}
              />
            )}

            {/* Modal options warehouse*/}
            <ModalOptionsWarehouse
              warehousesList={warehousesList}
              wareh={wareh}
              editName={editName}
              setWarehouseToEdit={setWarehouseToEdit}
              setOpenEditModal={setOpenEditModal}
              setShowWarehouse={setShowWarehouse}
              setSelectedWarehouseId={setSelectedWarehouseId}
              setWarehouseName={setWarehouseName}
              resetWarehouses={resetWarehouses}
              setResetWarehouses={setResetWarehouses}
              saleWarehouse={saleWarehouse}
              deleteWarehouseSelected={deleteWarehouseSelected}
              openCloseSelect={openCloseSelect}
              closeOptions={closeOptions}
              showWarehouse={showWarehouse}
              showEdit={showEdit}
              selectedWarehouseId={selectedWarehouseId}
              buttonDelete={buttonDelete}
              isHovered={isHovered}
              setIsHovered={setIsHovered}
              setStateResponse={setStateResponse}
              setWarehousesList={setWarehousesList}
              resetProducts={resetProducts}
              setResetProducts={setResetProducts}
              setSelectWarehouse={setSelectWarehouse}
            />
          </div>
        ))}

      {showDowngradeModal && (
        <ModalAcceptCancel
          title="Almacén deshabilitado"
          showButtons={{
            showButtonClose: true,
            showButtonOne: false,
            showButtonTwo: false,
          }}
          actionButtonClose={() => setShowDowngradeModal(false)}
        >
          <div className={style.downgradeModalContent}>
            <h2 className={style.modal__access__denied__title}>
              Lo sentimos, no tienes permisos para acceder a este almacén.
            </h2>
            <div className={style.modal__access__denied__icon__container}>
              <span className={style.modal__access__denied__icon}>
                <IconWrapper icon={HiLockClosed} size={48} color="#937cf4" />
              </span>
            </div>
            <p className={style.modal__access__denied__description}>
              Este almacén está deshabilitado automáticamente debido a la
              cantidad de almacenes disponibles según tu plan actual. Para
              habilitarlo nuevamente, actualiza tu plan a uno superior o ajusta
              la cantidad de almacenes disponibles.
            </p>
            <ButtonWithoutIcon
              isPrimary
              textBttn="Actualizar plan"
              handleClick={() => window.location.replace("/mi-cuenta/planes")}
            />
          </div>
        </ModalAcceptCancel>
      )}
    </div>
  );
};

export default WarehouseSearchResults;
