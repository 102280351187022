import { NavigationBar, ProductView } from "./components/NavigationBar";
import InformationView from "./views/InformationView/InformationView";
import StockMovements from "./views/StockMovements/StockMovements";
import LotsView from "./views/Lots/Lots";
import { LotsProvider } from "./views/Lots/context/LotsContext";

const ProductForm = ({
  availableHistory,
  setAvailableHeaderAction,
  submitButtonRef,
  setHasUnsavedChanges,
  setStateResponse,
  currentView,
  setCurrentView,
  errorView,
  setErrorView,
  id,
}) => {
  const renderView = () => {
    switch (currentView) {
      case ProductView.INFORMATION:
        return (
          <InformationView
            editable={availableHistory}
            setAvailableHeaderAction={setAvailableHeaderAction}
            submitButtonRef={submitButtonRef}
            setHasUnsavedChanges={setHasUnsavedChanges}
            setStateResponse={setStateResponse}
            setErrorView={setErrorView}
            errorView={errorView}
          />
        );
      case ProductView.STOCK_MOVEMENTS:
        return <StockMovements />;
      case ProductView.LOTS:
        return <LotsView />;
      default:
        return <InformationView />;
    }
  };

  return (
    <>
      <NavigationBar
        currentView={currentView}
        setCurrentView={setCurrentView}
      />
      {renderView()}
    </>
  );
};

export default ProductForm;
