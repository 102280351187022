import { LotsProvider } from "@Components/ProductForm/views/Lots/context/LotsContext";
import useScreenWidth from "@Hooks/useScreenWidth/useScreenWidth";
import BaseProduct from "@Pages/BaseProduct/BaseProduct";

const CreateProduct = () => {
  const screenWidth = useScreenWidth();
  return (
    <LotsProvider productId={null}>
      <BaseProduct
        title={"Nuevo producto"}
        availableHistory={false}
        titleMobile={"Nuevo Producto"}
        screenWidth={screenWidth}
      />
    </LotsProvider>
  );
};

export default CreateProduct;
