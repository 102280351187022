import React from "react";
import style from "./Lots.module.css";
import {
  TableWrapper,
  PaginationWrapper,
  DateInput,
  ButtonWithoutIcon,
  SelectInput,
  SlideNotification,
  CriticalErrorPage,
  IconWithOptions,
  Icon,
  ConfirmModal,
} from "../../../../resources";
import { formatDate } from "@Utilities/formatDate";
import { useLotsContext } from "./context/LotsContext";
import { TripleDotsIcon } from "@Models/icons/icons";
import LotCreationModal from "./components/LotCreationModal/LotCreationModal";
import { deleteLotAdapter } from "@Adapters/lots/deleteLot.adapter";
import { useParams } from "react-router";

const Lots: React.FC = () => {
  const { id: productId } = useParams();

  const {
    lots,
    isLoadingTable,
    isLoadingButton,
    currentPage,
    setCurrentPage,
    maxPage,
    form,
    setForm,
    error,
    handleFilter,
    stateResponse,
    setStateResponse,
    errorView,
    providers,
    isLoadingProviders,
    selectedLot,
    setSelectedLot,
    isLotModalVisible,
    setIsLotModalVisible,
    isDeleteLotModalVisible,
    setIsDeleteLotModalVisible,
    handleDeleteLot,
    handleCloseModal,
    handleLotCreated,
    handleLotUpdated,
    handleLotDeleted,
    isDeletingLot,
    setIsDeletingLot,
  } = useLotsContext();

  const titles = [
    "Código",
    "Unidades",
    "Precio unitario",
    "Proveedor",
    "Fecha de ingreso",
    "Fecha de vencimiento",
    "", // Columna para opciones
  ];

  const lotsTableRows = lots.map((lot) => ({
    code: lot.code,
    units: lot.units,
    unitPrice: `S/. ${lot.unitPrice.toFixed(2)}`,
    provider: lot.provider.name,
    entryDate: formatDate(lot.entryDate),
    expirationDate: formatDate(lot.expirationDate),
    options: (
      <IconWithOptions
        containerStyles={{
          borderRadius: "6px",
        }}
        icon={
          <span className={style.icon_options}>
            <Icon path={TripleDotsIcon} size={16} alt={"Tres puntos"} />
          </span>
        }
        actions={[
          {
            label: "Eliminar",
            handler: () => handleDeleteLot(lot),
          },
        ]}
      />
    ),
  }));

  const providersItems = providers.map((provider) => {
    return {
      value: provider.id,
      name: provider.name,
    };
  });

  if (errorView.errorLots) {
    return (
      <div id="viuti-front-mainContent">
        <CriticalErrorPage />
        <SlideNotification
          state={stateResponse}
          clearStatus={() => setStateResponse({ message: "", status: 0 })}
        />
      </div>
    );
  }

  return (
    <section className={style.lotsContainer}>
      <div className={style.headerFilter}>
        <SelectInput
          label="Proveedor"
          value={form.providerId}
          items={[
            { name: "Todos los proveedores", value: "Todos" },
            ...(isLoadingProviders ? [] : providersItems),
          ]}
          handleChange={(e) => setForm({ ...form, providerId: e.target.value })}
          isDisabled={isLoadingProviders}
        />
        <div className={style.containerDate}>
          <DateInput
            label="Fecha de inicio"
            inputProps={{
              placeholder: "Seleccionar fecha",
              value: form.startDate,
              onChange: (e) => setForm({ ...form, startDate: e.target.value }),
            }}
          />
        </div>
        <div className={style.containerDate}>
          <DateInput
            label="Fecha de fin"
            inputProps={{
              placeholder: "Seleccionar fecha",
              value: form.endDate,
              onChange: (e) => setForm({ ...form, endDate: e.target.value }),
            }}
            touched={true}
            error={error.endDate}
          />
        </div>
        <ButtonWithoutIcon
          textBttn="Filtrar"
          handleClick={handleFilter}
          isLoading={isLoadingButton}
          isPrimary={false}
          isDisabled={false}
        />
      </div>

      <TableWrapper
        titles={titles}
        data={lotsTableRows}
        isLoading={isLoadingTable}
        TableWrapperStyle={{ fontSize: "13px" }}
        containerStyle={{ width: "100%" }}
        containerTableStyle={{ width: "100%" }}
      />

      {maxPage > 1 && (
        <PaginationWrapper
          currentPagination={currentPage}
          setCurrentPagination={setCurrentPage}
          optionsPerPage={10}
          numberOfButtons={maxPage}
        />
      )}

      <LotCreationModal
        isVisible={isLotModalVisible}
        setIsVisible={setIsLotModalVisible}
        setSelectedLot={setSelectedLot}
        editMode={!!selectedLot}
        readMode={!!selectedLot}
        initialData={selectedLot}
        onSuccess={(lot) => {
          if (selectedLot) {
            handleLotUpdated(lot);
          } else {
            handleLotCreated(lot);
          }
          handleCloseModal();
        }}
      />
      <ConfirmModal
        title={"Eliminar lote"}
        message={
          <p className={style.delete_lot_modal__body}>
            Estás seguro que deseas remover el lote <b>{selectedLot?.code}</b>{" "}
            del inventario? Esta acción no afectará al stock del producto.
          </p>
        }
        buttonLbl={"Confirmar"}
        controller={{
          visible: isDeleteLotModalVisible,
          setVisible: setIsDeleteLotModalVisible,
          isLoading: isDeletingLot,
          isDisabled: isDeletingLot,
        }}
        confirmAction={async () => {
          setIsDeletingLot(true);
          try {
            const result = await deleteLotAdapter(
              { ...selectedLot, productId: Number(productId) },
              setStateResponse
            );
            return result;
          } finally {
            setIsDeletingLot(false);
          }
        }}
        successAction={async () => {
          return handleLotDeleted(selectedLot);
        }}
      />
    </section>
  );
};

export default Lots;
