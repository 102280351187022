import { getProviders_service } from "@Services/supplier/providers.service";

interface Provider {
  id: number;
  name: string;
}

export const getProvidersAdapter = async (
  setStateResponse: (state: { message: string; status: number }) => void
) => {
  try {
    // const response = await getProviders_service();

    const response = {
      isSuccess: true,
      data: [
        { id: 1, name: "Provider 1" },
        { id: 2, name: "Provider 2" },
        { id: 3, name: "Provider 3" },
      ],
    };

    if (!response.isSuccess) {
      throw new Error("Error al obtener los proveedores");
    }

    return {
      isSuccess: true,
      providers: response.data,
    };
  } catch (error) {
    setStateResponse({
      message: "Error al cargar los proveedores",
      status: 400,
    });
    return {
      isSuccess: false,
      providers: [],
    };
  }
};
