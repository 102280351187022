import { baseUrl, getData, postData } from "@Services/constServices";

interface IPayload {
  supplierId: number;
  codeBatch: string;
  expirationDate: string;
  creationDate: string;
  unitCost: number;
  productId: number;
  quantity: number;
}

export const createLot_service = async (payload: IPayload) => {
  const url = `${baseUrl}/Product/CreateBatch`;
  const successMessage = "Lote creado correctamente";
  const errorMessage =
    "Hubo un error al crear el lote. Por favor, intente nuevamente.";
  return postData(url, payload, successMessage, errorMessage);
};
