import { getSearchSupplierByName_service } from "@Services/supplier/getSearchSupplierByName.service";

export const getSearchSupplierByName_adapter = async (
  search: string,
  maxResultsToShow: number
) => {
  const response = await getSearchSupplierByName_service(
    search,
    maxResultsToShow
  );
  if (!response.isSuccess) {
    return {
      isSuccess: false,
      status: response.status,
      message: response.message,
      data: [],
    };
  }

  const adaptedData = response.data.map((supplier) => {
    return {
      id: supplier.id,
      name: supplier.fullName,
      taxNumber: supplier.taxNumber,
    };
  });

  return {
    isSuccess: true,
    status: response.status,
    message: response.message,
    data: adaptedData,
  };
};
