import React, { createContext, useContext } from "react";
import { useLots } from "../hooks/useLots";
import { ILot, LotCreationForm } from "@Models/interfaces/lots.interface";

interface LotsContextType {
  lots: ILot[];
  isLoadingTable: boolean;
  isLoadingButton: boolean;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  maxPage: number;
  form: any;
  setForm: (form: any) => void;
  error: any;
  handleFilter: () => void;
  stateResponse: any;
  setStateResponse: (state: any) => void;
  errorView: any;
  providers: any[];
  isLoadingProviders: boolean;
  selectedLot: ILot | null;
  setSelectedLot: (lot: ILot | null) => void;
  isLotModalVisible: boolean;
  setIsLotModalVisible: (visible: boolean) => void;
  isDeleteLotModalVisible: boolean;
  setIsDeleteLotModalVisible: (visible: boolean) => void;
  handleDeleteLot: (lot: ILot) => void;
  handleLotCreated: (lot: ILot) => void;
  handleLotUpdated: (lot: ILot) => void;
  handleLotDeleted: (lot: ILot) => void;
  handleCloseModal: () => void;
  isDeletingLot: boolean;
  setIsDeletingLot: (isDeleting: boolean) => void;
}

const LotsContext = createContext<LotsContextType | undefined>(undefined);

export const LotsProvider: React.FC<{
  children: React.ReactNode;
  productId: number | null;
}> = ({ children, productId }) => {
  const lotsState = useLots(productId);

  return (
    <LotsContext.Provider value={lotsState}>{children}</LotsContext.Provider>
  );
};

export const useLotsContext = () => {
  const context = useContext(LotsContext);
  if (context === undefined) {
    throw new Error("useLotsContext must be used within a LotsProvider");
  }
  return context;
};
