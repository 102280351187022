import { useEffect, useRef, useState } from "react";
// @ts-ignore
import style from "./MainTable.module.css";
import {
  PlusIcon,
  LeaveIcon,
  MinusWithFrameIcon,
  OpenBoxIcon,
  RedirectIcon,
  TrashBinIcon,
  CommonArrowRightIcon,
} from "../../../../models/icons/icons";
import { useNavigate } from "react-router";
import { closeEdit } from "./functions/closeEdit";
import { openEdit } from "./functions/openEdit";
import { deleteProduct } from "./functions/deleteProduct";
import { clickHandler } from "@Utilities/clickHandler";
import useScreenWidth from "@Hooks/useScreenWidth/useScreenWidth";
import {
  PaginationWrapper,
  CriticalErrorPage,
  Icon,
  validateModuleAccess,
} from "../../../../resources/index";
// @ts-ignore
import { IconWrapper } from "@viuti/recursos";
import { truncateString } from "@Utilities/truncateString";
import { copyCodeToClipboard } from "@Utilities/copyCodeToClipboard";
import { HiOutlineSquare2Stack } from "react-icons/hi2";
import {
  DEPOSIT_PRODUCTS_UUID,
  MODIFY_PRODUCTS_UUID,
  TRANSFER_PRODUCTS_UUID,
  WITHDRAW_PRODUCTS_UUID,
} from "@Models/const/securityAccess";

const MainTable = ({
  productsList,
  productsList_,
  setModal,
  searchInput,
  setProductDelete,
  setUnitOrVolume,
  setIdProductModal,
  selectWarehouse,
  showWarehouse,
  setShowWarehouse,
  setSelectProduct,
  selectProduct,
  openFilter,
  currentPagination,
  setCurrentPagination,
  productsListCategory,
  setStateResponse,
  errorView,
}) => {
  const navigate = useNavigate();
  const screenWidth = useScreenWidth();
  const [showTooltip, setShowTooltip] = useState(false);
  const [showTooltipTransfer, setShowTooltipTransfer] = useState(false);

  const [hoveredProductId, setHoveredProductId] = useState(null);
  const containerRef = useRef(null);
  const [highlightedCode, setHighlightedCode] = useState(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        // Clic fuera del contenedor, establecer selectProduct en null
        setSelectProduct(null);
      }
    };

    // Agregar el evento al documento
    document.addEventListener("mousedown", handleClickOutside);

    // Limpiar el evento al desmontar el componente
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef]);
  // cuando se da un click se cierran los tooltips
  useEffect(() => {
    if (showTooltip) {
      setShowTooltip(false);
    }
    if (showTooltipTransfer) {
      setShowTooltipTransfer(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectProduct]);

  if (errorView.errorProduct) {
    return <CriticalErrorPage />;
  }
  if (!productsList?.length) {
    return (
      <div className={style.tableMsg}>
        <span className={style.icon__container}>
          <Icon path={OpenBoxIcon} alt="vacío" size={"76px"} />
        </span>
        {searchInput?.length ? (
          <p className={style.emptyWarehouse__message}>
            No hay resultados para tu búsqueda
            <span>Intenta usar otras palabras.</span>
          </p>
        ) : (
          <p className={style.emptyWarehouse__message}>
            {productsList_.length && !productsList.length
              ? "No existen productos para el filtro aplicado"
              : "No tienes productos creados en este almacén."}
            {productsList_.length !== 0 && !productsList.length ? (
              <span>Intenta usar otros filtros.</span>
            ) : (
              <span>
                Para agregar, puedes crear uno, o si tienes productos en otros
                almacenes podrás transferirlos también.
              </span>
            )}
          </p>
        )}
      </div>
    );
  } else {
    return (
      <button
        className={style.container}
        ref={containerRef}
        onClick={() => {
          // navigate(`detalles/${product.productLocalId}`)

          if (selectProduct) {
            setSelectProduct(null);
          }
        }}
      >
        {productsList?.map((product, index) => (
          <button
            onMouseEnter={() => setHoveredProductId(product.productLocalId)}
            onMouseLeave={() => setHoveredProductId(null)}
            className={`${style.table} ${
              hoveredProductId === product.productLocalId &&
              !selectProduct &&
              !openFilter
                ? style.hoverTable
                : ""
            }`}
            key={index}
            style={{
              borderTop: index !== 0 ? "1px solid #dad1f4" : "",
              backgroundColor:
                selectProduct === product.productLocalId ? "#f5f5f5" : "",
            }}
            onClick={(e) => {
              // navigate(`detalles/${product.productLocalId}`)
              if (selectProduct) {
                setSelectProduct(null);
              }
              if (
                hoveredProductId === product.productLocalId &&
                !selectProduct &&
                !openFilter
              ) {
                validateModuleAccess(MODIFY_PRODUCTS_UUID) &&
                  navigate(`detalles/${product.productLocalId}`);
              }
            }}
          >
            <div className={style.leftSide}>
              <div>
                <p className={style.productName}>{product.productName}</p>
                {product.code && (
                  <div className={style.infoWidth}>
                    <button
                      className={`${style.cod} ${
                        highlightedCode === product.code &&
                        style.highlightedCode
                      }`}
                      onMouseEnter={() => setHighlightedCode(product.code)}
                      onMouseLeave={() => setHighlightedCode(null)}
                      onClick={(e) => {
                        e?.stopPropagation();
                        copyCodeToClipboard(product.code);
                        setStateResponse({
                          message: "Copiado al portapapeles",
                          status: 200,
                        });
                      }}
                    >
                      <p>Cod:</p>
                      <p>{product.code}</p>
                      <IconWrapper
                        icon={HiOutlineSquare2Stack}
                        color={
                          highlightedCode === product.code
                            ? "#33333"
                            : "#937cf4"
                        }
                      />
                    </button>
                  </div>
                )}
              </div>
              {screenWidth > 900 && (
                <div className={style.containerImg}>
                  <div className={style.brandImg}>
                    {truncateString(
                      procesarNombre(product?.brand?.brandName),
                      20
                    )}
                  </div>
                </div>
              )}
              <div className={style.units}>
                <p>{product.stock} unidades</p>
                <button
                  className={style.containerRedirectionIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    selectProduct
                      ? closeEdit(setSelectProduct)
                      : openEdit(
                          product.productLocalId,
                          setSelectProduct,
                          setShowWarehouse,
                          showWarehouse
                        );
                  }}
                >
                  <Icon
                    path={RedirectIcon}
                    size={16}
                    alt="Abrir opciones"
                    color={"#45348e"}
                  />
                </button>

                {selectProduct === product.productLocalId && (
                  <div
                    className={style.containerEditionVolume}
                    style={{
                      zIndex: 100,
                      height: "140px",
                      marginTop: "11.5rem",
                    }}
                  >
                    <button
                      className={style.enabledButton}
                      onClick={() =>
                        validateModuleAccess(DEPOSIT_PRODUCTS_UUID) &&
                        clickHandler(
                          navigate,
                          `ingresar/${product.productLocalId}`
                        )
                      }
                    >
                      <Icon path={PlusIcon} alt="Ingresar" />
                      Ingresar
                    </button>
                    <button
                      onClick={() => {
                        if (!validateModuleAccess(TRANSFER_PRODUCTS_UUID))
                          return;
                        if (product.stock <= 0) return;
                        setModal(1);
                        setSelectProduct(null);
                        setUnitOrVolume({ unit: true });
                        setIdProductModal(product.productLocalId);
                      }}
                      onMouseEnter={() => {
                        if (product.stock <= 0) {
                          setShowTooltipTransfer(true);
                        }
                      }}
                      onMouseLeave={() => {
                        setShowTooltipTransfer(false);
                      }}
                      className={
                        product.stock > 0
                          ? style.enabledButton
                          : style.disabledButton
                      }
                    >
                      <Icon
                        path={LeaveIcon}
                        alt="Transferir"
                        color={product.stock > 0 ? "#937cf4" : "#dadada"}
                      />
                      Transferir
                      {showTooltipTransfer && (
                        <div className={style.tooltip}>
                          Stock insuficiente para realizar esta acción.
                        </div>
                      )}
                    </button>
                    <button
                      onClick={() => {
                        if (!validateModuleAccess(WITHDRAW_PRODUCTS_UUID))
                          return;
                        if (product.stock <= 0) return;
                        clickHandler(
                          navigate,
                          `retirar/${product.productLocalId}`
                        );
                      }}
                      className={
                        product.stock > 0
                          ? style.enabledButton
                          : style.disabledButton
                      }
                      onMouseEnter={() => {
                        if (product.stock <= 0) {
                          setShowTooltip(true);
                        }
                      }}
                      onMouseLeave={() => {
                        if (product.stock <= 0) {
                          setShowTooltip(false);
                        }
                      }}
                    >
                      <Icon
                        path={MinusWithFrameIcon}
                        alt="Retirar"
                        color={product.stock > 0 ? "#937cf4" : "#dadada"}
                      />
                      Retirar
                      {showTooltip && (
                        <div className={style.tooltip}>
                          Stock insuficiente para realizar esta acción.
                        </div>
                      )}
                    </button>
                  </div>
                )}
              </div>
              {product.stock === 0 && !selectProduct && screenWidth < 900 && (
                <button
                  title="Eliminar producto"
                  className={style.trashCan}
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteProduct(product, setModal, setProductDelete);
                  }}
                >
                  <Icon
                    path={TrashBinIcon}
                    size={16}
                    color={"#C36363"}
                    alt={"Eliminar"}
                  />
                </button>
              )}
            </div>
            {screenWidth > 900 && (
              <div className={style.rightSide}>
                {!selectProduct && !openFilter && screenWidth > 900 && (
                  <button
                    className={`${style.selectBubbleContainer}`}
                    style={{
                      opacity:
                        hoveredProductId === product.productLocalId ? 1 : 0,
                    }}
                    onClick={() =>
                      validateModuleAccess(MODIFY_PRODUCTS_UUID) &&
                      navigate(`detalles/${product.productLocalId}`)
                    }
                    title="Ver producto"
                  >
                    <Icon
                      path={CommonArrowRightIcon}
                      size={16}
                      alt={"Ver producto"}
                      color={"#45348e"}
                    />
                  </button>
                )}
                {product.stock === 0 && !selectProduct && (
                  <button
                    title="Eliminar producto"
                    className={style.trashCan}
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteProduct(product, setModal, setProductDelete);
                    }}
                  >
                    <Icon
                      path={TrashBinIcon}
                      size={16}
                      color={"#C36363"}
                      alt={"Eliminar"}
                    />
                  </button>
                )}
              </div>
            )}
          </button>
        ))}
        <span className={style.containerPagination}>
          {productsListCategory.length > 10 && (
            <PaginationWrapper
              currentPagination={currentPagination}
              setCurrentPagination={setCurrentPagination}
              optionsPerPage={10}
              options={productsListCategory}
            />
          )}
        </span>
      </button>
    );
  }
};

export default MainTable;

export const procesarNombre = (nombre: string) => {
  // Obtener la primera palabra de la cadena
  // if (!nombre) return "";
  // if (nombre?.length > 10) {
  //   // Si la primera palabra tiene más de 12 caracteres, retornar tres puntos
  //   return nombre.slice(0, 10) + "...";
  // } else {
  //   return nombre?.toUpperCase();
  // }
  return nombre;
};
