import React from "react";
import { formatToSoles } from "@Utilities/formatToSoles";
import {
  HiArrowDownTray,
  HiArrowUpTray,
  HiCheck,
  HiOutlineArrowTopRightOnSquare,
} from "react-icons/hi2";
import style from "./WarehouseStatusCard.module.css";
// @ts-ignore
import { validateModuleAccess, IconWrapper } from "@viuti/recursos";

interface WarehouseStatusCardProps {
  warehouseValue: number;
  incomingMovements: number;
  outgoingMovements: number;
  salesMovements: number;
}

const WarehouseStatusCard: React.FC<WarehouseStatusCardProps> = ({
  warehouseValue,
  incomingMovements,
  outgoingMovements,
  salesMovements,
}) => {
  const handleDetailsClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const isAccessOk: boolean = validateModuleAccess("");

    if (!isAccessOk) e.preventDefault();
  };

  return (
    <div className={style.card}>
      <div className={style.mainInfo}>
        <div className={style.titleContainer}>
          <h3 className={style.title}>Valorización del almacén</h3>
          <p className={style.value}>{formatToSoles(warehouseValue)}</p>
        </div>
      </div>
      <div className={style.movementsContainer}>
        <MovementItem
          Icon={<IconWrapper icon={HiArrowDownTray} size={16} />}
          label="Entradas"
          value={incomingMovements}
        />
        <MovementItem
          Icon={<IconWrapper icon={HiArrowUpTray} size={16} />}
          label="Salidas"
          value={outgoingMovements}
        />
        <MovementItem
          Icon={<IconWrapper icon={HiCheck} size={16} />}
          label="Ventas"
          value={salesMovements}
        />
      </div>
      <a
        href="/reportes/movimientos-almacenes"
        className={style.detailsLink}
        onClick={handleDetailsClick}
      >
        Ver detalles
        <IconWrapper icon={HiOutlineArrowTopRightOnSquare} size={16} />
      </a>
    </div>
  );
};

interface MovementItemProps {
  Icon: React.ReactElement;
  label: string;
  value: number;
}

const MovementItem: React.FC<MovementItemProps> = ({ Icon, label, value }) => (
  <div className={style.movementItem}>
    {Icon}
    <span className={style.movementLabel}>{label}</span>
    <span className={style.movementValue}>{value}</span>
  </div>
);

export default WarehouseStatusCard;
