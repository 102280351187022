import React from "react";
// @ts-ignore
import styles from "./NavigationBar.module.css";

// Creamos un enum para manejar las vistas
export enum ProductView {
  INFORMATION = "information",
  STOCK_MOVEMENTS = "stockMovements",
  LOTS = "lots",
}

interface NavigationBarProps {
  currentView: ProductView;
  setCurrentView: (view: ProductView) => void;
}

export const NavigationBar: React.FC<NavigationBarProps> = ({
  currentView,
  setCurrentView,
}) => {
  return (
    <div className={styles.NavigationContainer}>
      <button
        className={`${styles.NavigationButton} ${
          currentView === ProductView.INFORMATION ? styles.selected : ""
        }`}
        onClick={() => setCurrentView(ProductView.INFORMATION)}
      >
        <p>Información</p>
      </button>
      <button
        className={`${styles.NavigationButton} ${
          currentView === ProductView.STOCK_MOVEMENTS ? styles.selected : ""
        }`}
        onClick={() => setCurrentView(ProductView.STOCK_MOVEMENTS)}
      >
        <p>Movimientos de stock</p>
      </button>
      <button
        className={`${styles.NavigationButton} ${
          currentView === ProductView.LOTS ? styles.selected : ""
        }`}
        onClick={() => setCurrentView(ProductView.LOTS)}
      >
        <p>Lotes</p>
      </button>
    </div>
  );
};
