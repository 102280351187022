import { ILot } from "@Models/interfaces/lots.interface";
import { getLots_service } from "@Services/lots/getLots.service";

interface GetLotsParams {
  productId: number;
  pageNumber: number;
  itemsPerPage: number;
  startDate?: string;
  endDate?: string;
  providerId?: string;
}

interface GetLotsResponse {
  isSuccess: boolean;
  data: {
    maxPages: number;
    lots: ILot[];
  };
}

export const getLotsAdapter = async (
  params: GetLotsParams,
  setStateResponse: (state: { message: string; status: number }) => void,
  setErrorView: (error: { errorLots: boolean }) => void
): Promise<GetLotsResponse> => {
  try {
    const response = await getLots_service(
      params.pageNumber,
      params.startDate,
      params.endDate,
      params.providerId,
      params.productId
    );

    const lots = response.data.map((lot: any) => ({
      id: lot.batchId,
      code: lot.code,
      units: lot.quantity,
      unitPrice: lot.unitCost,
      provider: {
        id: lot.supplierId,
        name: lot.supplierName,
        companyId: null,
      },
      entryDate: lot.registrationDate,
      expirationDate: lot.expirationDate,
      status: lot.expirationStatus,
    }));

    if (!response.isSuccess) {
      throw new Error("Error al obtener los lotes");
    }

    const data = {
      maxPages: 1,
      lots,
    };

    return {
      isSuccess: true,
      data,
    };
  } catch (error) {
    setErrorView({ errorLots: true });
    setStateResponse({
      message: "Error al cargar los lotes",
      status: 400,
    });
    return {
      isSuccess: false,
      data: {
        maxPages: 0,
        lots: [],
      },
    };
  }
};
