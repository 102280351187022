import { useEffect, useRef, useState } from "react";
import {
  LoadingSpinner,
  FileUploader,
  InfoNote,
  HeaderNavigation,
  SlideNotification,
  SelectInput,
  ModalAcceptCancel,
  trackUserAction,
  IconWrapper,
  // @ts-ignore
} from "@viuti/recursos";
import { HiArrowDownTray } from "react-icons/hi2";
import style from "./CreateMasiveProducts.module.css";
import { downloadMasiveProducts } from "@Adapters/spreadsheets/downloadMasiveProducts/downloadMasiveProducts";
import { uploadSpreadsheet } from "@Adapters/productsAdapters/uploadProductsSpreadsheet/uploadSpreadsheet";
import InformationView from "../../components/ProductForm/views/InformationView/InformationView";
import { bulkUploadViaInvoice_adapter } from "@Adapters/productsAdapters/products/bulkUploadViaInvoice.adapter";
import Loader from "@Components/Loader/Loader";
import { convertDate } from "@Utilities/formatDate";
import BulkUploadViaInvoice from "@Components/BulkUploadViaInvoice/BulkUploadViaInvoice";

const CreateMasiveProducts = () => {
  const [isDownloadingSpreadsheet, setIsDownloadingSpreadsheet] =
    useState(false);
  const [loadSpreadsheetFile, setLoadSpreadsheetFile] = useState(null);
  const [isSubmittingFile, setIsSubmittingFile] = useState(false);
  const [stateResponse, setStateResponse] = useState({
    message: "",
    status: 0,
  });
  const [isSummaryVisible, setIsSummaryVisible] = useState(false);
  const [showModalCreateProduct, setShowModalCreateProduct] = useState(false);
  const [selectedOption, setSelectedOption] = useState("2");
  const [productInformation, setProductInformation] = useState(null);
  const [availableHeaderAction, setAvailableHeaderAction] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [createdProducts, setCreatedProducts] = useState([]);
  const [idSupplier, setIdSupplier] = useState(null);
  const [infoCreatedProducts, setInfoCreatedProducts] = useState([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [invoiceData, setInvoiceData] = useState<InvoiceData | null>({
    supplierName: "",
    supplierAddress: "",
    supplierRuc: "",
    customerName: "",
    customerAddress: "",
    customerRuc: "",
    issueDate: "",
    dueDate: "",
    billCode: "",
    billSeries: "",
    billNumber: "",
    billTotalAmount: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingInvoice, setIsLoadingInvoice] = useState(false);
  const [errorView, setErrorView] = useState({
    errorProduct: false,
    errorBrand: false,
    errorSubBrand: false,
    errorCategory: false,
  });
  const [selectedWarehouse, setSelectedWarehouse] = useState({
    warehouseId: "",
    warehouseName: "",
  });
  const vista_carga_masiva_productos = 31;
  trackUserAction(vista_carga_masiva_productos);

  const handleDeleteInvoice = () => {
    setCreatedProducts([]);
    setProducts([]);
    setInvoiceData({
      supplierName: "",
      supplierAddress: "",
      supplierRuc: "",
      customerName: "",
      customerAddress: "",
      customerRuc: "",
      issueDate: "",
      dueDate: "",
      billCode: "",
      billSeries: "",
      billNumber: "",
      billTotalAmount: 0,
    });
    setIsSummaryVisible(false);
  };
  const fromSpreadsheet = selectedOption === "1";

  const fromInvoice = selectedOption === "2";

  const submitButtonRef = useRef(null);
  const options = [
    {
      value: "1",
      name: "Desde planilla",
    },
    {
      value: "2",
      name: "Desde factura de compra",
    },
  ];

  const handleFormSubmit = () => {
    if (submitButtonRef.current) {
      submitButtonRef.current.click();
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        setShowModalCreateProduct(false);
      }, 2000);
    }
  };

  const handleLoadSpreadsheetFile = async () => {
    setIsSubmittingFile(true);
    const response = await uploadSpreadsheet(loadSpreadsheetFile);
    if (!response.isSuccess) {
      setStateResponse({
        message: response.message,
        status: response.status,
      });
    } else {
      setLoadSpreadsheetFile(null);
    }
    setIsSubmittingFile(false);
  };

  const handleDownloadTemplate = async () => {
    setIsDownloadingSpreadsheet(true);
    const reponse = await downloadMasiveProducts();
    if (!reponse.isSuccess) {
      setStateResponse({
        message: reponse.message,
        status: reponse.status,
      });
    }
    setIsDownloadingSpreadsheet(false);
  };

  const handleUploadInvoice = async () => {
    setIsSubmittingFile(true);

    const data = {
      billSupplierDetail: {
        supplierDocumentNumber: String(invoiceData.supplierRuc),
        supplierDocumentType: 1,
        supplierAddress: invoiceData.supplierAddress,
        supplierName: invoiceData.supplierName,
        billCode: invoiceData.billCode,
        billTotalAmount: invoiceData.billTotalAmount,
        dueDate: convertDate(invoiceData.dueDate),
        issueDate: convertDate(invoiceData.issueDate),
      },
      productsDetail: products.map((product) => ({
        productId: Number(product.id) || 0,
        quantity: Number(product.quantity),
        unitProductPrice: Number(product.price),
      })),
    };

    await bulkUploadViaInvoice_adapter(
      data,
      selectedWarehouse.warehouseId,
      selectedWarehouse.warehouseName,
      setStateResponse,
      setIsSubmittingFile,
      products,
      idSupplier
    );
  };

  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(true);

  useEffect(() => {
    if (fromInvoice && products.length > 0) {
      const allProductsExist = products.every(
        (product) => product.existInWarehouse
      );
      setIsConfirmButtonDisabled(!allProductsExist);
    }
  }, [products, fromInvoice]);

  if (isLoadingInvoice) {
    return (
      <div id="viuti-front-mainContent">
        <HeaderNavigation
          title="Carga masiva de productos"
          previousAction={() => {
            if (isSummaryVisible) {
              handleDeleteInvoice();
              return;
            }
            window.history.back();
          }}
          previousActionMovement={"back"}
          buttonProps={{
            textBttn: "Confirmar",
            handleClick: fromSpreadsheet
              ? handleLoadSpreadsheetFile
              : handleUploadInvoice,
            isHidden: false,
            isPrimary: true,
            isDisabled:
              isSubmittingFile ||
              (fromSpreadsheet && !loadSpreadsheetFile) ||
              (fromInvoice && isConfirmButtonDisabled),
            isLoading: isSubmittingFile,
          }}
        />
        <Loader />
      </div>
    );
  }

  return (
    <div id="viuti-front-mainContent">
      <HeaderNavigation
        title="Carga masiva de productos"
        previousAction={() => {
          if (isSummaryVisible) {
            setIsConfirmButtonDisabled(true);
            handleDeleteInvoice();
            return;
          }
          window.history.back();
        }}
        previousActionMovement={"back"}
        buttonProps={{
          textBttn: "Confirmar",
          handleClick: fromSpreadsheet
            ? handleLoadSpreadsheetFile
            : handleUploadInvoice,
          isHidden: false,
          isPrimary: true,
          isDisabled:
            isSubmittingFile ||
            (fromSpreadsheet && !loadSpreadsheetFile) ||
            (fromInvoice && isConfirmButtonDisabled),
          isLoading: isSubmittingFile,
        }}
      />
      <div className={style.create__masive__products__main__container}>
        {!isSummaryVisible && (
          <div className={style.select__input__container}>
            <SelectInput
              label="Selecciona como deseas cargar los productos"
              value={selectedOption}
              items={options}
              handleChange={(e) => {
                setSelectedOption(e.target.value);
              }}
              required
            />
          </div>
        )}

        {fromSpreadsheet && (
          <div className={style.create__masive__products__container}>
            <section className={style.create__masive__products__section}>
              <h1 className={style.create__masive__products__title}>
                Instrucciones
              </h1>
              <p className={style.create__masive__products__description}>
                Descarga, completa y sube una planilla con los datos de los
                nuevos productos que quieres crear en tu almacén en solo cuatro
                pasos.
              </p>
            </section>
            <InfoNote type="warning">
              <p>
                Antes de subir la planilla, asegúrate de que cumple con las
                siguientes condiciones:
              </p>
              <ul>
                <li>
                  <p>
                    No cambies los nombres de las columnas ni elimines las filas
                    o columnas preexistentes.
                  </p>
                </li>
                <li>
                  <p>
                    Recuerda que los productos que crees no podrán ser
                    modificados masivamente. Para modificar productos de forma
                    individual puedes hacerlo desde la sección de productos.
                  </p>
                </li>
                <li>
                  <p>
                    Completa solo las filas en blanco, no elimines las primeras
                    filas de colores que tienen los nombres de las columnas y
                    las ayudas.
                  </p>
                </li>
                <li>
                  <p>
                    Si tu producto tiene variantes, puedes crearlas copiando las
                    filas.
                  </p>
                </li>
              </ul>
            </InfoNote>
            <div className={style.create__masive__products__actions__container}>
              <button
                className={`${style.download__template__button} ${
                  isDownloadingSpreadsheet
                    ? style.download__template__button__disabled
                    : ""
                }`}
                onClick={handleDownloadTemplate}
                disabled={isDownloadingSpreadsheet}
              >
                <span className={style.download__template__button__icon}>
                  {isDownloadingSpreadsheet ? (
                    <LoadingSpinner size={16} color="#000000" />
                  ) : (
                    <IconWrapper icon={HiArrowDownTray} size={18} />
                  )}
                </span>
                <span className={style.download__template__button__text}>
                  <h3 className={style.download__template__button__text__title}>
                    Descarga la planilla para crear productos masivamente.
                  </h3>
                  <p
                    className={
                      style.download__template__button__text__description
                    }
                  >
                    Configura la planilla con los datos de los productos que
                    quieres crear en tu almacén y súbela a Prikly.
                  </p>
                </span>
              </button>
              <FileUploader
                label="Sube la planilla"
                file={loadSpreadsheetFile}
                setFile={setLoadSpreadsheetFile}
                accept={[".xls", ".xlsx"]}
              />
            </div>
          </div>
        )}

        {selectedOption === "2" && (
          <div className={style.upload__from__invoice__container}>
            {!isSummaryVisible && <InvoiceInstructions />}
            <BulkUploadViaInvoice
              isSummaryVisible={isSummaryVisible}
              setIsSummaryVisible={setIsSummaryVisible}
              setShowModalCreateProduct={setShowModalCreateProduct}
              setProductInformation={setProductInformation}
              createdProducts={createdProducts}
              infoCreatedProducts={infoCreatedProducts}
              products={products}
              setProducts={setProducts}
              invoiceData={invoiceData}
              setInvoiceData={setInvoiceData}
              selectedWarehouse={selectedWarehouse}
              setSelectedWarehouse={setSelectedWarehouse}
              setStateResponse={setStateResponse}
              isLoadingInvoice={isLoadingInvoice}
              setIsLoadingInvoice={setIsLoadingInvoice}
            />
            {showModalCreateProduct && (
              <ModalAcceptCancel
                title="Nuevo producto"
                showButtons={{
                  showButtonClose: true,
                  showButtonOne: true,
                  showButtonTwo: true,
                }}
                buttonOne={{
                  textButtonOne: "Cancelar",
                  actionButtonOne: () => setShowModalCreateProduct(false),
                }}
                buttonTwo={{
                  textButtonTwo: "Guardar",
                  actionButtonTwo: () => handleFormSubmit(),
                  isDisabled: !availableHeaderAction || isLoading,
                  isLoading: isLoading,
                }}
                actionButtonClose={() => setShowModalCreateProduct(false)}
              >
                <InformationView
                  editable={false}
                  setAvailableHeaderAction={setAvailableHeaderAction}
                  submitButtonRef={submitButtonRef}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                  setStateResponse={setStateResponse}
                  setErrorView={setErrorView}
                  errorView={errorView}
                  productInformation={productInformation}
                  warehouseSelected={productInformation.warehouse}
                  setCreatedProducts={setCreatedProducts}
                  setInfoCreatedProducts={setInfoCreatedProducts}
                />
              </ModalAcceptCancel>
            )}
          </div>
        )}

        <SlideNotification
          state={stateResponse}
          clearStatus={() => setStateResponse({ message: "", status: 0 })}
        />
      </div>
    </div>
  );
};

export default CreateMasiveProducts;

const InvoiceInstructions = () => (
  <div className={style.create__masive__products__container}>
    <section className={style.create__masive__products__section}>
      <h1 className={style.create__masive__products__title}>Instrucciones</h1>
      <p className={style.create__masive__products__description}>
        Carga la factura en formato XML que contenga los datos de los productos
        que deseas agregar a tu almacén.
      </p>
    </section>
    <InfoNote type="warning">
      <br />
      <ul>
        <li>
          <p>La factura debe estar en formato XML.</p>
        </li>
        <li>
          <p>
            Cuando cargues la factura, aquellos productos que no existan en el
            sitema deberán ser registrados.
          </p>
        </li>
        <li>
          <p>
            Si el proveedor no existe en el sistema, se creará automáticamente.
          </p>
        </li>
      </ul>
    </InfoNote>
  </div>
);

export interface Product {
  code: string;
  name: string;
  nameInvoice: string;
  description: string;
  price: number;
  productAveragePrice: number | null;
  quantity: number;
  total: number;
  isNew?: boolean;
  existInWarehouse?: boolean;
  isFree?: boolean;
  id?: number | null;
  brand: {
    brandId: number;
    brandName: string;
  };
  category: {
    categoryId: number;
    categoryName: string;
  }[];
  emergencyStock: number;
}

export interface InvoiceData {
  supplierName: string;
  supplierAddress: string;
  supplierRuc: string;
  customerName: string;
  customerAddress: string;
  customerRuc: string;
  issueDate: string;
  dueDate: string;
  billCode: string;
  billSeries: string;
  billNumber: string;
  billTotalAmount: number;
}
