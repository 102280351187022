import React, { useRef } from "react";
import styles from "./FileUpload.module.css";
import { HiArrowUpTray } from "react-icons/hi2";
// @ts-ignore
import { IconWrapper } from "@viuti/recursos";
import { formatDate } from "@Utilities/formatDate";

interface Product {
  code: string;
  name: string;
  description: string;
  price: number;
  quantity: number;
  total: number;
}
interface Props {
  onFileUpload: (products: Product[], billData: any) => void;
  selectedWarehouse: any;
  setError: any;
}
const FileUpload: React.FC<Props> = ({
  onFileUpload,
  selectedWarehouse,
  setError,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!selectedWarehouse.warehouseId) {
      setError((prev) => ({
        ...prev,
        warehouseError: "Primero debes seleccionar un almacén",
      }));
      return;
    }
    const files = e.dataTransfer.files;
    if (files.length) handleFile(files[0]);
  };

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!selectedWarehouse.warehouseId) {
      setError((prev) => ({
        ...prev,
        warehouseError: "Primero debes seleccionar un almacén",
      }));
      return;
    }
    const files = e.target.files;
    if (files && files.length) {
      handleFile(files[0]);
      e.target.value = ""; // Resetea el valor del input
    }
  };

  const handleFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target?.result as string;
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(content, "text/xml");

      // Función auxiliar para buscar elementos con namespace
      const getElementByTagNameNS = (
        element: Element,
        namespace: string,
        tagName: string
      ) => {
        const elements = element.getElementsByTagNameNS(namespace, tagName);
        return elements.length > 0 ? elements[0] : null;
      };
      // Extracting supplier information
      const accountingSupplierParty = xmlDoc.getElementsByTagNameNS(
        "urn:oasis:names:specification:ubl:schema:xsd:CommonAggregateComponents-2",
        "AccountingSupplierParty"
      )[0];

      const supplierParty = accountingSupplierParty
        ? getElementByTagNameNS(
            accountingSupplierParty,
            "urn:oasis:names:specification:ubl:schema:xsd:CommonAggregateComponents-2",
            "Party"
          )
        : null;

      const supplierPartyIdentification = supplierParty
        ? getElementByTagNameNS(
            supplierParty,
            "urn:oasis:names:specification:ubl:schema:xsd:CommonAggregateComponents-2",
            "PartyIdentification"
          )
        : null;

      const supplierRuc = supplierPartyIdentification
        ? getElementByTagNameNS(
            supplierPartyIdentification,
            "urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2",
            "ID"
          )?.textContent || ""
        : "";

      const supplierName = supplierParty
        ? getElementByTagNameNS(
            supplierParty,
            "urn:oasis:names:specification:ubl:schema:xsd:CommonAggregateComponents-2",
            "PartyLegalEntity"
          )?.getElementsByTagNameNS(
            "urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2",
            "RegistrationName"
          )[0]?.textContent || ""
        : "";

      // Extracting customer information
      const customerName =
        xmlDoc.getElementsByTagName("cbc:RegistrationName")[1]?.textContent ||
        "";
      const customerRuc =
        xmlDoc.getElementsByTagName("cbc:ID")[2]?.textContent || "";

      // Extracting dates
      const issueDate =
        xmlDoc.getElementsByTagName("cbc:IssueDate")[0]?.textContent || "";
      const dueDate =
        xmlDoc.getElementsByTagName("cbc:DueDate")[0]?.textContent || "";

      // Get all cbc:ID elements
      const idElements = xmlDoc.getElementsByTagNameNS(
        "urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2",
        "ID"
      );

      let billCode = "";

      // Iterate through the ID elements to find the correct one
      for (let i = 0; i < idElements.length; i++) {
        const parentNode = idElements[i].parentNode;
        if (parentNode && parentNode.nodeName === "Invoice") {
          billCode = idElements[i].textContent || "";
          break;
        }
      }

      const billSeries = billCode.split("-")[0] || "";

      const billNumber = billCode.split("-")[1] || "";

      const billTotalAmount = parseFloat(
        xmlDoc.getElementsByTagName("cbc:PayableAmount")[0]?.textContent || "0"
      );

      const invoiceLines = xmlDoc.getElementsByTagNameNS(
        "urn:oasis:names:specification:ubl:schema:xsd:CommonAggregateComponents-2",
        "InvoiceLine"
      );
      const products: Product[] = [];

      const getElementTextContent = (xmlDoc, tagName, index = 0) => {
        const elements = xmlDoc.getElementsByTagName(tagName);
        return elements[index]?.textContent || "-";
      };

      const supplierAddress =
        getElementTextContent(xmlDoc, "cbc:Line", 0) || "-";
      const customerAddress =
        getElementTextContent(xmlDoc, "cbc:Line", 1) || "-";

      Array.from(invoiceLines).forEach((line) => {
        const sellersItemIdentification = getElementByTagNameNS(
          line,
          "urn:oasis:names:specification:ubl:schema:xsd:CommonAggregateComponents-2",
          "SellersItemIdentification"
        );
        const code = sellersItemIdentification
          ? getElementByTagNameNS(
              sellersItemIdentification,
              "urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2",
              "ID"
            )?.textContent || ""
          : "";

        const description =
          getElementByTagNameNS(
            line,
            "urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2",
            "Description"
          )?.textContent || "";

        // Extract the correct unit price from the Price element
        const priceElement = getElementByTagNameNS(
          line,
          "urn:oasis:names:specification:ubl:schema:xsd:CommonAggregateComponents-2",
          "Price"
        );
        const price = parseFloat(
          priceElement
            ? getElementByTagNameNS(
                priceElement,
                "urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2",
                "PriceAmount"
              )?.textContent || "0"
            : "0"
        );

        const quantity = parseFloat(
          getElementByTagNameNS(
            line,
            "urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2",
            "InvoicedQuantity"
          )?.textContent || "0"
        );

        // The total is already correct in LineExtensionAmount
        const total = parseFloat(
          getElementByTagNameNS(
            line,
            "urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2",
            "LineExtensionAmount"
          )?.textContent || "0"
        );

        products.push({
          code,
          name: description.trim() || "",
          description: description.trim() || "",
          price,
          quantity,
          total,
        });
      });
      const billData = {
        billSupplierDetail: {
          supplierId: supplierRuc.trim() || "",
          billCode,
          billSeries,
          billNumber,
          billTotalAmount,
          dueDate: formatDate(dueDate),
          issueDate: formatDate(issueDate),
          supplierName: supplierName?.trim() || "",
          supplierAddress,
          customerName,
          customerRuc,
          customerAddress,
        },
        productsDetail: products.map((product) => ({
          productId: 0,
          quantity: product.quantity,
          isChangePrice: false,
          newProductPrice: product.price,
        })),
      };

      onFileUpload(products, billData);
    };
    reader.readAsText(file);
  };

  return (
    <div>
      <h2
        className={
          selectedWarehouse.warehouseId ? styles.title : styles.title__disabled
        }
      >
        Sube la factura de compra
      </h2>

      <button
        className={
          selectedWarehouse.warehouseId
            ? styles.fileUpload
            : styles.fileUpload__disabled
        }
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={() => {
          if (!selectedWarehouse.warehouseId) {
            setError((prev) => ({
              ...prev,
              warehouseError: "Primero debes seleccionar un almacén",
            }));
          } else {
            fileInputRef.current?.click();
          }
        }}
      >
        <section className={styles.fileUpload__section__message}>
          <p
            className={styles.fileUpload__section__message__icon}
            style={{
              color: selectedWarehouse.warehouseId ? "" : "#9a9a9a",
            }}
          >
            <IconWrapper
              icon={HiArrowUpTray}
              size={16}
              color={selectedWarehouse.warehouseId ? "#937cf4" : "#9a9a9a"}
            />
            <span
              className={styles.dragDrop__section__message__text__span}
              style={{
                color: selectedWarehouse.warehouseId ? "#937cf4" : "#9a9a9a",
              }}
            >
              Seleccionar
            </span>{" "}
            o arrastrar el archivo aquí
          </p>
          <p className={styles.dragDrop__section__message__format}>
            Solo puedes subir archivos xml
          </p>
        </section>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileInput}
          accept=".xml"
          style={{ display: "none" }}
          disabled={!selectedWarehouse.warehouseId}
          key={Date.now()} // Esto forzará la re-renderización del input
        />
      </button>
    </div>
  );
};

export default FileUpload;
