import { getData, baseUrl } from "@Services/constServices";

export const getLots_service = async (
  pageNumber: number,
  startDate: string,
  endDate: string,
  providerId: string,
  productId: number
) => {
  const url = `${baseUrl}/Product/GetBatcher?productId=${productId}`;
  // ?pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}&providerId=${providerId}`;
  const successMessage = "Lotes encontrados correctamente";
  const errorMessage =
    "Hubo un error al buscar los lotes. Por favor, intente nuevamente.";
  return getData(url, successMessage, errorMessage);
};
