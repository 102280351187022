import axios from "axios";

const Error401URL = window.location.origin + "/";
const ServerErrorURL = window.location.origin + "/time-out";
const TIMEOUT = 15000;

axios.interceptors.request.use((config) => {
  config.timeout = TIMEOUT;
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      localStorage.clear();
      window.location.href = Error401URL;
    } /* else if (status >= 501 && status < 600) {
      if (
        status === 502 &&
        error.config.url ===
          "https://gw.viuti.io/viuti2/v1/CobroCaja/CobrarVenta"
      ) {
        // if the url is cobrar caja, then redirect to the error page
      } //Por ahora, para pasar cobrar caja
      window.location.href = ServerErrorURL;
    } */ else {
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);
