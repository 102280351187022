// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UvCZCL2OGicWak_3WoHR {
  width: 100%;
  min-width: 500px;
  max-width: 500px;

  @media (max-width: 720px) {
    min-width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ProductForm/views/Lots/components/LotCreationModal/LotCreationModal.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,gBAAgB;;EAEhB;IACE,eAAe;EACjB;AACF","sourcesContent":[".modalContent {\n  width: 100%;\n  min-width: 500px;\n  max-width: 500px;\n\n  @media (max-width: 720px) {\n    min-width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContent": `UvCZCL2OGicWak_3WoHR`
};
export default ___CSS_LOADER_EXPORT___;
