// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pILss16wLBO9hQg03XI5 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
}

.W9u1gm964b_0QAgDFWWD {
  display: flex;
  gap: 16px;
  align-items: flex-end;
}

.YbaMJYqh07Gkux34FegM {
  min-width: 200px;
}

.flJmQc8szyEQd0BgXTE5 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.g7Wp_G0VfjVwebUgiQ_s {
  color: #727070;
  text-align: center;
  font-weight: 400;
  margin-bottom: 30px;
  line-height: 22.5px;
}

`, "",{"version":3,"sources":["webpack://./src/components/ProductForm/views/Lots/Lots.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,aAAa;AACf;;AAEA;EACE,aAAa;EACb,SAAS;EACT,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;AACrB","sourcesContent":[".lotsContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  padding: 24px;\n}\n\n.headerFilter {\n  display: flex;\n  gap: 16px;\n  align-items: flex-end;\n}\n\n.containerDate {\n  min-width: 200px;\n}\n\n.icon_options {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.delete_lot_modal__body {\n  color: #727070;\n  text-align: center;\n  font-weight: 400;\n  margin-bottom: 30px;\n  line-height: 22.5px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lotsContainer": `pILss16wLBO9hQg03XI5`,
	"headerFilter": `W9u1gm964b_0QAgDFWWD`,
	"containerDate": `YbaMJYqh07Gkux34FegM`,
	"icon_options": `flJmQc8szyEQd0BgXTE5`,
	"delete_lot_modal__body": `g7Wp_G0VfjVwebUgiQ_s`
};
export default ___CSS_LOADER_EXPORT___;
