import {
  baseUrl,
  handleApiError,
  handleSuccessfullResponse,
  headerAuthorization,
} from "@Services/constServices";
import axios from "axios";

interface IPayload {
  batchId: number;
  productId: number;
}

export const deleteLot_service = async (payload: IPayload) => {
  try {
    const url = `${baseUrl}/Product/DeleteBatch`;
    const successMessage = "Lote eliminado correctamente";

    const response = await axios.delete(url, {
      ...headerAuthorization,
      data: {
        batchId: payload.batchId,
        productId: payload.productId,
      },
    });

    return handleSuccessfullResponse(response, successMessage);
  } catch (error) {
    const errorMessage =
      "Hubo un error al eliminar el lote. Por favor, intente nuevamente.";
    return handleApiError(error, errorMessage);
  }
};
