import { ILot } from "@Models/interfaces/lots.interface";
import { deleteLot_service } from "@Services/lots/deleteLot.service";

export const deleteLotAdapter = async (
  payload: ILot & {
    productId: number;
  },
  setStateResponse: (state: { message: string; status: number }) => void
) => {
  try {
    const adapterPayload = {
      batchId: payload.id,
      productId: payload.productId,
    };

    const response = await deleteLot_service(adapterPayload);

    if (!response.isSuccess) {
      throw new Error("Error al crear el lote");
    }

    const lotData = {
      id: response.data.batchId,
    };

    return {
      ...response,
      data: lotData,
    };
  } catch (error) {
    setStateResponse({
      message: "Error al crear el lote",
      status: 400,
    });
    return {
      isSuccess: false,
      status: 400,
      data: null,
    };
  }
};
