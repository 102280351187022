import { ProductView } from "@Components/ProductForm/components/NavigationBar";
import { CREATE_LOTS_UUID } from "@Models/const/securityAccess";
// @ts-ignore
import { validateModuleAccess } from "@viuti/recursos";
import { useLotCreationForm } from "../views/Lots/hooks/useLotCreationForm";

export interface HeaderButtonConfig {
  textBttn: string;
  handleClick: () => void;
  isDisabled: boolean;
  isPrimary: boolean;
  isLoading?: boolean;
  isHidden: boolean;
}

interface ButtonConfigParams {
  currentView: ProductView;
  handleFormSubmit: () => void;
  isLoading: boolean;
  availableHeaderAction: boolean;
  onOpenLotModal: () => void;
}

export const getHeaderButtonConfig = ({
  currentView,
  handleFormSubmit,
  isLoading,
  availableHeaderAction,
  onOpenLotModal,
}: ButtonConfigParams): HeaderButtonConfig => {
  const configs: Record<ProductView, HeaderButtonConfig> = {
    [ProductView.INFORMATION]: {
      textBttn: "Guardar",
      handleClick: handleFormSubmit,
      isDisabled: !availableHeaderAction || isLoading,
      isPrimary: true,
      isLoading,
      isHidden: false,
    },
    [ProductView.STOCK_MOVEMENTS]: {
      textBttn: "",
      handleClick: () => {},
      isDisabled: true,
      isPrimary: true,
      isHidden: true,
    },
    [ProductView.LOTS]: {
      textBttn: "Crear lote",
      handleClick: () =>
        validateModuleAccess(CREATE_LOTS_UUID) && onOpenLotModal(),
      isDisabled: false,
      isPrimary: true,
      isLoading: false,
      isHidden: false,
    },
  };

  return configs[currentView];
};
