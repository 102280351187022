import { useRef, useState } from "react";
import { HeaderNavigation, SlideNotification } from "../../resources";
import style from "./BaseProduct.module.css";
import ProductForm from "@Components/ProductForm/ProductForm";
import ModalUnsavedChanges from "@Components/ModalUnsavedChanges/ModalUnsavedChanges";
import { useNavigate, useParams } from "react-router";
import { ProductView } from "@Components/ProductForm/components/NavigationBar";
import { getHeaderButtonConfig } from "@Components/ProductForm/functions/headerButtonConfig";
import { useLotsContext } from "@Components/ProductForm/views/Lots/context/LotsContext";

const BaseProduct = ({ title, availableHistory, titleMobile, screenWidth }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentView, setCurrentView] = useState<ProductView>(
    ProductView.INFORMATION
  );
  const [availableHeaderAction, setAvailableHeaderAction] = useState(false);
  const formSubmitButton = useRef(null);
  const [changesModalVisible, setChangesModalVisible] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [stateResponse, setStateResponse] = useState({
    message: "",
    status: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorView, setErrorView] = useState({
    errorProduct: false,
    errorBrand: false,
    errorSubBrand: false,
    errorCategory: false,
  });

  const { setIsLotModalVisible, setSelectedLot } = useLotsContext();

  const handleFormSubmit = () => {
    if (formSubmitButton.current) {
      formSubmitButton.current.click();
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }
  };

  const handleActionChange = (action) => {
    setAvailableHeaderAction(action);
  };

  const handlePreviousAction = () => {
    if (!isLoading) {
      if (hasUnsavedChanges) {
        setChangesModalVisible(true);
      } else {
        navigate(`/`);
      }
    }
  };

  const buttonConfig = getHeaderButtonConfig({
    currentView,
    handleFormSubmit,
    isLoading,
    availableHeaderAction,
    onOpenLotModal: () => {
      setIsLotModalVisible(true);
      setSelectedLot(null);
    },
  });

  return (
    <div id="viuti-front-mainContent">
      <HeaderNavigation
        title={title}
        previousAction={handlePreviousAction}
        previousActionMovement={""}
        buttonProps={buttonConfig}
      />
      <div className={style.container}>
        <ProductForm
          id={id}
          availableHistory={availableHistory}
          setAvailableHeaderAction={handleActionChange}
          submitButtonRef={formSubmitButton}
          setHasUnsavedChanges={setHasUnsavedChanges}
          setStateResponse={setStateResponse}
          errorView={errorView}
          setErrorView={setErrorView}
          currentView={currentView}
          setCurrentView={setCurrentView}
        />
      </div>

      <ModalUnsavedChanges
        changesModalVisible={changesModalVisible}
        setChangesModalVisible={setChangesModalVisible}
        navigate={navigate}
        screenWidth={screenWidth}
      />
      <SlideNotification
        state={stateResponse}
        clearStatus={() => setStateResponse({ message: "", status: 0 })}
      />
    </div>
  );
};

export default BaseProduct;
