import {
  ModalAcceptCancel,
  LotsCreationForm,
} from "../../../../../../resources/index";
import { useLotsContext } from "../../context/LotsContext";
import { useLotCreationForm } from "../../hooks/useLotCreationForm";
import styles from "./LotCreationModal.module.css";

const LotCreationModal = ({
  isVisible,
  setIsVisible,
  setSelectedLot,
  editMode = false,
  readMode = false,
  initialData = null,
  onSuccess,
}) => {
  const {
    handleCreateLot,
    handleInputChange,
    handleProviderSearch,
    lotForm,
    lotFormError,
    codeError,
    isCodeLoading,
    isSubmitting,
    suppliers,
    isLoadingSuppliers,
    isFormValid,
  } = useLotCreationForm(editMode, initialData, readMode, onSuccess);

  return (
    isVisible && (
      <ModalAcceptCancel
        title={readMode ? "Detalles del lote" : "Crear lote"}
        showButtons={{
          showButtonClose: true,
          showButtonOne: false,
          showButtonTwo: !readMode,
        }}
        buttonTwo={{
          textButtonTwo: editMode ? "Guardar cambios" : "Crear lote",
          actionButtonTwo: handleCreateLot,
          isLoading: isSubmitting,
          isDisabled: !isFormValid || isSubmitting,
        }}
        visibility={{
          visible: isVisible,
        }}
        actionButtonClose={() => {
          setIsVisible(false);
          setSelectedLot(null);
        }}
      >
        <div className={styles.modalContent}>
          <LotsCreationForm
            handleInputChange={handleInputChange}
            lotForm={lotForm}
            lotFormError={lotFormError}
            codeError={codeError}
            isCodeLoading={isCodeLoading}
            onSearchProvider={handleProviderSearch}
            providerOptions={suppliers}
            isSearchingProvider={isLoadingSuppliers}
            isReadMode={readMode}
          />
        </div>
      </ModalAcceptCancel>
    )
  );
};

export default LotCreationModal;
