import { useState, useEffect } from "react";
import { getLotsAdapter } from "@Adapters/lots/getLots.adapter";
import { getProvidersAdapter } from "@Adapters/Supplier/getProviders.adapter";
import { ILot } from "@Models/interfaces/lots.interface";

export const useLots = (productId: number | null) => {
  const [lots, setLots] = useState<ILot[]>([]);
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const getFirstDayOfMonth = () => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    return firstDayOfMonth.toISOString().split("T")[0];
  };

  const getCurrentDate = () => {
    return new Date().toISOString().split("T")[0];
  };

  const [form, setForm] = useState({
    startDate: getFirstDayOfMonth(),
    endDate: getCurrentDate(),
    providerId: "Todos",
  });
  const [error, setError] = useState({
    endDate: "",
  });

  const [stateResponse, setStateResponse] = useState({
    message: "",
    status: 0,
  });

  const [errorView, setErrorView] = useState({
    errorLots: false,
  });

  const [providers, setProviders] = useState([]);
  const [isLoadingProviders, setIsLoadingProviders] = useState(true);

  const [selectedLot, setSelectedLot] = useState<any>(null);
  const [isLotModalVisible, setIsLotModalVisible] = useState(false);
  const [isDeleteLotModalVisible, setIsDeleteLotModalVisible] = useState(false);
  const [isDeletingLot, setIsDeletingLot] = useState(false);

  const fetchLots = async (page: number = currentPage) => {
    setIsLoadingTable(true);
    const response = await getLotsAdapter(
      {
        productId,
        pageNumber: page,
        itemsPerPage: 10,
        startDate: form.startDate,
        endDate: form.endDate,
        providerId: form.providerId === "Todos" ? undefined : form.providerId,
      },
      setStateResponse,
      setErrorView
    );

    if (response.isSuccess) {
      setLots(response.data.lots);
      setMaxPage(response.data.maxPages);
    }
    setIsLoadingTable(false);
  };

  const handleFilter = async () => {
    if (form.startDate > form.endDate) {
      setError({ endDate: "Debe ser posterior a fecha inicio" });
      return;
    }
    setError({ endDate: "" });
    setIsLoadingButton(true);
    await fetchLots(1);
    setCurrentPage(1);
    setIsLoadingButton(false);
  };

  const fetchProviders = async () => {
    setIsLoadingProviders(true);
    const response = await getProvidersAdapter(setStateResponse);
    if (response.isSuccess) {
      setProviders(response.providers);
    }
    setIsLoadingProviders(false);
  };

  const handleDeleteLot = (lot: ILot) => {
    setSelectedLot(lot);
    setIsDeleteLotModalVisible(true);
  };

  const handleLotCreated = (newLot: ILot) => {
    setLots((prevLots) => [newLot, ...prevLots]);
  };

  const handleLotUpdated = (updatedLot: ILot) => {
    setLots((prevLots) =>
      prevLots.map((lot) => (lot.id === updatedLot.id ? updatedLot : lot))
    );
  };

  const handleLotDeleted = (lot: ILot) => {
    setLots((prevLots) => prevLots.filter((l) => l.id !== lot.id));
  };
  
  const handleCloseModal = () => {
    setSelectedLot(null);
    setIsLotModalVisible(false);
  };

  useEffect(() => {
    if (productId) {
      fetchLots();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  useEffect(() => {
    if (currentPage > 1) {
      fetchLots();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    fetchProviders();
  }, []);

  return {
    lots,
    isLoadingTable,
    isLoadingButton,
    currentPage,
    setCurrentPage,
    maxPage,
    form,
    setForm,
    error,
    handleFilter,
    stateResponse,
    setStateResponse,
    errorView,
    setErrorView,
    providers,
    isLoadingProviders,
    selectedLot,
    setSelectedLot,
    isLotModalVisible,
    setIsLotModalVisible,
    isDeleteLotModalVisible,
    setIsDeleteLotModalVisible,
    handleDeleteLot,
    handleLotCreated,
    handleLotUpdated,
    handleCloseModal,
    handleLotDeleted,
    isDeletingLot,
    setIsDeletingLot,
  };
};
